/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';

	const slide = () => {
		$('.index-main-slider').on('init', function(){
			$('.slick-slide[data-slick-index="0"]').addClass('js-moving');
		}).slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 4000,
			loop: true,
			speed: 1000,
			arrows: false,
			dots: false,
			pauseOnHover: false,
			fade: true
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var slide_num = $('.index-main-slider .slick-slide').length -1;
			$('.slick-slide[data-slick-index="'+ (currentSlide - 1) +'"]').removeClass('js-moving');
			$('.slick-slide[data-slick-index="'+nextSlide+'"]').addClass('js-moving');
			// console.log(currentSlide);
			// console.log(slide_num);
			if(currentSlide == 1) {
				$('.slick-slide[data-slick-index="'+ slide_num +'"]').removeClass('js-moving');
			}
		});
		$('.index-ticker').slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 4000,
			loop: true,
			speed: 1000,
			arrows: false,
			dots: false
		});
	};

	slide();

})(window.jQuery, window.FUNCTIONS);
